var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "inner" },
    [
      _c("header", { staticClass: "app-welcome-message" }, [
        _c("h1", [_vm._v("NlSfbCategory")]),
      ]),
      _c(
        "v-container",
        { attrs: { fluid: "", "pa-0": "", "mt-4": "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("v-data-table", {
                    attrs: {
                      dense: "",
                      options: _setup.gridOptions,
                      headers: _setup.headers,
                      "hide-default-footer": "",
                      items: _setup.gridData,
                      loading: _setup.gridIsLoading,
                      "items-per-page": _setup.pageSize,
                      "server-items-length": _setup.totalItems,
                    },
                    on: {
                      "update:options": [
                        function ($event) {
                          _setup.gridOptions = $event
                        },
                        _setup.changedOptions,
                      ],
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.actions`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.editItem(item.id)
                                    },
                                  },
                                },
                                [_vm._v(" mdi-pencil ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }